import React, { useContext } from 'react'
import { MyContext } from '../../context'
import text from '../../data/text'

function Legal() {
  const context = useContext(MyContext)

  return (
    <>
      <div className='legal'>
        <div className='container'>
          <div>{text.legal.legal[context.state.lang]}</div>
        </div>
      </div>
    </>
  )
}
export default Legal
