import React, { useState, useContext } from 'react'
import { MyContext } from '../../context'
import text from '../../data/text'
import { NavLink, Link } from 'react-router-dom'
import bars from '../../assets/img/bars.svg'
import close from '../../assets/img/close-menu.svg'

import './header.css'

function Header() {
  const [isOpen, setIsOpen] = useState(false)
  const context = useContext(MyContext)

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className='header'>
      <div className='container'>
        <div className='large'>
          <div className='header-headline'>
            <h1>
              <Link
                to='/'
                className='home-link'
              >
                Victor Nahon
                <div>{text.nav.lawyer[context.state.lang]}</div>
              </Link>
            </h1>
          </div>
          <nav className='navigation-items'>
            <NavLink
              to='about'
              className='navigation-item'
            >
              {text.nav.about[context.state.lang]}
            </NavLink>
            <NavLink
              to='contact'
              className='navigation-item'
            >
              {text.nav.contact[context.state.lang]}
            </NavLink>
          </nav>
        </div>
        <div className='small'>
          <div></div>
          <h1>
            <Link
              to='/'
              className='home-link'
            >
              <div className='header-headline'>
                Victor Nahon<div>{text.nav.lawyer[context.state.lang]}</div>
              </div>
            </Link>
          </h1>
          <nav className='navigation-items'>
            <div className='navigation-wrap'>
              <img
                src={bars}
                width='20'
                alt=''
                className='menu-icon'
                onClick={toggleMenu}
              />
            </div>
          </nav>
          {isOpen && (
            <div className='menu'>
              <img
                src={close}
                width='20'
                alt='Close menu'
                className='menu-icon close'
                onClick={toggleMenu}
              />
              <div className='small'>
                <div></div>
                <div className='header-headline'>
                  <h1>
                    <Link
                      to='/'
                      className='home-link'
                      onClick={toggleMenu}
                    >
                      Victor Nahon
                      <div>{text.nav.lawyer[context.state.lang]}</div>
                    </Link>
                  </h1>
                </div>
                <div></div>
              </div>
              <div className='menu-items'>
                <NavLink
                  to='about'
                  className='navigation-item'
                  onClick={toggleMenu}
                >
                  {text.nav.about[context.state.lang]}
                </NavLink>

                <NavLink
                  to='contact'
                  className='navigation-item'
                  onClick={toggleMenu}
                >
                  {text.nav.contact[context.state.lang]}
                </NavLink>
                <NavLink
                  to='legal'
                  className='navigation-item'
                  onClick={toggleMenu}
                >
                  {text.footer.legal[context.state.lang]}
                </NavLink>
              </div>
              {/*               <div className='language-selector'>
                <div
                  className={
                    context.state.lang === 'fr' ? 'active lang' : 'lang '
                  }
                  onClick={() => {
                    context.langHandler('fr')
                  }}
                >
                  fr
                </div>
                <div>-</div>
                <div
                  className={
                    context.state.lang === 'en' ? 'active lang' : 'lang '
                  }
                  onClick={() => {
                    context.langHandler('en')
                  }}
                >
                  en
                </div>
              </div> */}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default Header
