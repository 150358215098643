import React, { useContext } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './components/Home/Home'
import About from './components/About/About'
import Contact from './components/Contact/Contact'
import Legal from './components/Legal/Legal'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import text from './data/text'
import './assets/css/styles.css'
import { MyContext } from './context'
import './assets/fonts/fonts.css'
import CookieConsent from 'react-cookie-consent'

function App() {
  const context = useContext(MyContext)

  return (
    <Router>
      <Header />
      <div className='content'>
        <Routes>
          <Route
            path='/'
            element={<Home />}
          />
          <Route
            path='about'
            element={<About />}
          />
          <Route
            path='contact'
            element={<Contact />}
          />
          <Route
            path='legal'
            element={<Legal />}
          />
        </Routes>
      </div>
      <Footer />
      <CookieConsent
        location='bottom'
        buttonText={text.cookies.button[context.state.lang]}
        cookieName='myAwesomeCookieName2'
        expires={150}
      >
        {text.cookies.text[context.state.lang]}
      </CookieConsent>
    </Router>
  )
}

export default App
