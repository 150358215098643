import homeImg from '../../assets/img/home.webp'
import './home.css'

function Home() {
  return (
    <div className='home'>
      <div className='container'>
        <img
          src={homeImg}
          alt='Home'
        />
      </div>
    </div>
  )
}

export default Home
