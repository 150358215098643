import React, { useContext } from 'react'
import { MyContext } from '../../context'
import text from '../../data/text'

import img from '../../assets/img/victornahon.webp'
import './about.css'

function About() {
  const context = useContext(MyContext)

  return (
    <>
      <div className='about'>
        <div className='container'>
          <div className='grid'>
            <div>{text.about.about[context.state.lang]}</div>
            <img
              src={img}
              alt='Victor Nahon'
            />
          </div>
        </div>
      </div>
      <div className='areas'>
        <div className='container'>{text.areas.areas[context.state.lang]}</div>
      </div>
    </>
  )
}
export default About
