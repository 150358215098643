import React, { useContext } from 'react'
import { MyContext } from '../../context'
import { NavLink } from 'react-router-dom'
import text from '../../data/text'
import './footer.css'

function Footer() {
  const context = useContext(MyContext)

  function setVhVariable() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  
  window.addEventListener('resize', setVhVariable);
  
  setVhVariable();

  return (
    <div className='footer'>
      <div className='container'>
        <div>---</div>
        <NavLink to='legal'>{text.footer.legal[context.state.lang]}</NavLink>
      </div>
    </div>
  )
}
export default Footer
