import React, { useContext, useRef } from 'react'
import emailjs from '@emailjs/browser'
import './contact.css'
import text from '../../data/text'
import close from '../../assets/img/close-menu.svg'

import { MyContext } from '../../context'

function Contact() {
  const context = useContext(MyContext)
  const form = useRef()

  const sendEmail = (e) => {
    e.preventDefault()

    emailjs
      .sendForm(
        'service_aetqayi',
        'template_n6fgiy9',
        form.current,
        'N1kxZd_FRKmc2aKtT'
      )
      .then(
        (result) => {
          document.querySelector('.overlay-success').style.display = 'flex'
        },
        (error) => {
          document.querySelector('.overlay-error').style.display = 'flex'
        }
      )
    closeMessage()
  }

  const closeMessage = () => {
    const overlaySuccess = document.querySelector('.overlay-success')
    const overlayError = document.querySelector('.overlay-error')
    const formName = document.querySelector('form #Name')
    const formEmail = document.querySelector('form #Email')
    const formField = document.querySelector('form #field')

    overlayError.style.display = 'none'
    overlaySuccess.style.display = 'none'
    formName.value = ''
    formEmail.value = ''
    formField.value = ''
  }

  return (
    <div className='contact'>
      <div className='container'>
        <div>
          <div>4 cité Chaptal, Paris 9e</div>
          <a href='mailto:vn@nahonavocat.fr'>vn@nahonavocat.fr</a>
          <br />
          <a href='tel:+33 1 40 34 40 40'>01 40 34 40 40</a>
          <br />
          <br />
          <form
            className='contact-form'
            ref={form}
            onSubmit={sendEmail}
          >
            <div>
              <div>
                <input
                  type='text'
                  name='user_name'
                  data-name='Name'
                  placeholder={
                    text.contact.name.placeholder[context.state.lang]
                  }
                  id='Name'
                  required
                />
              </div>
              <div className=''>
                <input
                  type='email'
                  name='user_email'
                  data-name='Email'
                  placeholder={
                    text.contact.email.placeholder[context.state.lang]
                  }
                  id='Email'
                  required
                />
              </div>
            </div>

            <textarea
              id='field'
              name='message'
              placeholder={text.contact.message.placeholder[context.state.lang]}
              data-name='Field'
              className=''
              required
            />
            <input
              type='submit'
              value={text.contact.button[context.state.lang]}
              data-wait='Please wait...'
              className='button'
            />
          </form>
        </div>
        <div className='overlay-success'>
          <img
            src={close}
            width='20'
            alt='Close menu'
            className='menu-icon close'
            onClick={closeMessage}
          />
          <div>{text.contact.success[context.state.lang]}</div>
        </div>
        <div className='overlay-error'>
          <img
            src={close}
            width='20'
            alt='Close menu'
            className='menu-icon close'
            onClick={closeMessage}
          />
          <div>{text.contact.error[context.state.lang]}</div>
        </div>
      </div>
    </div>
  )
}

export default Contact
