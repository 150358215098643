const text = {
  nav: {
    home: {
      fr: 'accueil',
      en: 'home',
    },
    about: {
      fr: 'le cabinet',
      en: 'about',
    },
    contact: {
      fr: 'contact',
      en: 'contact',
    },
    lawyer: {
      fr: 'Avocat à la Cour',
      en: 'Lawyer',
    },
  },
  about: {
    about: {
      fr: (
        <>
          <h2>Présentation</h2>
          <p>
            Titulaire du Master II, Ingénierie des sociétés délivré par
            l’Institut du Droit des Affaires d’Aix-en-Provence (promotion 2012),
            Victor Nahon est inscrit au Barreau de Paris depuis janvier 2015.
          </p>
          <p>
            Installé à son compte depuis avril 2021, il exerce principalement
            dans le domaine du droit des affaires, tant en conseil qu’en
            contentieux.
          </p>
          <p>
            <a href='https://www.linkedin.com/in/victornahon/'>
              Voir son profil complet
            </a>
          </p>
        </>
      ),
      en: (
        <>
          <h2>About me</h2>
          <p>
            Holder of a Master's degree in Business Engineering (Class of 2012)
            issued by the Institute of Business Law of Aix-en-Provence, Victor
            Nahon has been a member of the Paris Bar since January 2015.
          </p>
          <p>
            Since April 2021, he has been working as a self-employed lawyer,
            specializing primarily in business law, both in advisory and
            litigation matters.
          </p>
          <p>
            <a href='https://www.linkedin.com/in/victornahon/'>
              See his full profile
            </a>
          </p>
        </>
      ),
    },
  },
  areas: {
    areas: {
      fr: (
        <>
          <h2>Compétences</h2>
          <div className='br'></div>
          <h3>Activité de conseil</h3>
          <p>
            Victor Nahon conseille et assiste les entreprises tout au long de
            leur existence.
          </p>
          <p>
            <span>Création de sociétés</span> : rédaction et négociation de
            statuts
          </p>
          <p>
            <span>Développement des sociétés</span> : émission de valeurs
            mobilières simples et complexes, pacte d’associés, cessions
            d’entreprises, juridique courant des sociétés, rédaction et
            négociation des contrats commerciaux et d’affaires courants (baux
            commerciaux, transactions et protocole d’accord, élaboration de
            contrats innomés), consultation juridique générale.
          </p>
          <p>
            <span> Prévention et traitement des entreprises en difficulté</span>
             : accompagnement du dirigeant auprès des organes de la procédure,
            acquisition et cession d’entreprise et d’actifs
          </p>
          <p>
            Victor Nahon conseille et assiste également les associés de sociétés
            pour le bon suivi de leurs investissements.
          </p>
          <div className='br'></div>
          <h3>Activité judiciaire</h3>
          <p>
            Victor Nahon intervient pour les entreprises et les particuliers
            (dirigeants, associés et investisseurs) devant les Tribunaux
            judiciaires et de commerce dans les contentieux économiques et
            commerciaux (responsabilité contractuelle, conflits entre associés,
            concurrence déloyale, rupture brutale de relations commerciales
            établies, …).
          </p>
          <p>
            Victor Nahon s’autorise à intervenir dans les litiges civils
            courants.
          </p>
        </>
      ),
      en: (
        <>
          <h2>Areas</h2>
          <div className='br'></div>
          <h3>Advisory Activity</h3>
          <p>
            Victor Nahon advises and assists companies throughout their
            existence.
          </p>
          <p>
            Company formation: drafting and negotiation of articles of
            association.
          </p>
          <p>
            Company development: issuance of simple and complex securities,
            shareholder agreements, company disposals, day-to-day legal affairs
            of companies, drafting and negotiation of commercial and business
            contracts (commercial leases, transactions and agreements, drafting
            of unnamed contracts), general legal consultation.
          </p>
          <p>
            Prevention and treatment of struggling companies: support of the
            company director in front of the procedure bodies, acquisition and
            disposal of companies and assets.
          </p>
          <p>
            Victor Nahon also advises and assists company shareholders for the
            proper monitoring of their investments.
          </p>
          <div className='br'></div>
          <h3>Judicial Activity</h3>
          <p>
            Victor Nahon represents companies and individuals (directors,
            shareholders, and investors) in economic and commercial disputes
            (contractual liability, disputes between partners, unfair
            competition, sudden termination of established commercial
            relationships, etc.) before the judicial and commercial courts.
          </p>
          <p>
            Victor Nahon is also authorized to intervene in common civil
            disputes.
          </p>
        </>
      ),
    },
  },
  legal: {
    legal: {
      fr: (
        <>
          <h2>Mentions légales</h2>
          <h3>Editeur</h3>
          <p>
            Monsieur Victor Nahon, avocat au Barreau de Paris, exerçant en
            entreprise individuelle sous le n°SIRET 810 165 266 00034.
          </p>
          <h3>Coordonnées</h3>
          <p>
            Domicile : 4 cité Chaptal 75009 Paris
            <br />
            Tél : 01.40.34.40.40
            <br />
            Mail : vn@nahonavocat.fr
          </p>
          <h3>Hébergeur du site</h3>
          <p>
            IONOS SARL, 7 place de la Gare BP 70109 57201 Sarreguemines Cedex
            (RCS Sarreguemines 431 303 775)
          </p>
          <h3>Contenu</h3>
          <p>
            Monsieur Victor Nahon dispose d’un droit d’utilisation exclusif du
            présent site ainsi que de la propriété des textes, signes
            distinctifs et photographies qui y sont intégrés. Sauf autorisation
            expresse, toute reproduction partielle ou intégrale de ces éléments
            est illicite et peut donner lieu à des poursuites.
          </p>
          <h3>Protection des données personnelles</h3>
          <p>
            En application des articles 13 et 14 du Règlement Général sur la
            Protection des Données (2016/679) et de l’article 32 de la loi
            Informatique et Libertés du 6 janvier 1978, l’éditeur du présent
            site vous informe que :
          </p>
          <ul>
            <li>
              L’ensemble des données personnelles que vous pourriez communiquer
              via le présent site internet sont protégées dans un système
              informatique sécurisé détenu par Monsieur Victor Nahon.
            </li>
            <li>
              Les données personnelles communiquées sont conservées (i) pendant
              un délai de 3 mois dans le cadre d’un simple échange via le
              formulaire de contact du site internet et (ii) pendant un délai de
              cinq années à compter du dernier acte juridique ou judiciaire
              réalisé par Monsieur Victor NAHON dans le cadre d’une relation
              avocat – client.
            </li>
            <li>
              Tout intéressé dispose d’un droit d’accès, de limitation, de
              rectification ou d’effacement de ses données personnelles, en
              adressant un email à l’adresse : vn@nahonavocat.fr. Il dispose
              également de la faculté de former une réclamation auprès de la
              CNIL s’il considère que ses données n’ont pas été protégées.
            </li>
          </ul>
          <h3>Médiation</h3>
          <p>
            Conformément aux dispositions de l'article L. 612-1 du code de la
            consommation, tout consommateur a le droit de recourir gratuitement
            à un médiateur de la consommation en vue de la résolution amiable du
            litige qui l'oppose à un professionnel. Les clients remplissant les
            conditions pour bénéficier de cette médiation peuvent avoir recours
            au médiateur de la consommation de la profession d'avocat :{' '}
            <a href='https://mediateur-consommation-avocat.fr'>
              https://mediateur-consommation-avocat.fr
            </a>
            .
          </p>
          <h3>Crédits</h3>
          <p>
            L'image figurant en page d'accueil est une création digitale de
            l'artiste Godwits{' '}
            <a href='https://opensea.io/fr/Godwits'>
              https://opensea.io/fr/Godwits
            </a>
            .
          </p>
        </>
      ),
      en: (
        <>
          <h2>Legal notice</h2>
          <h3>Publisher</h3>
          <p>
            Mr. Victor Nahon, lawyer at the Paris Bar, exercising as an
            individual entrepreneur under SIRET number 810 165 266 00034.
          </p>
          <h3>Contact details</h3>
          <p>
            Home: 4 cité Chaptal 75009 Paris
            <br />
            Tel: 01.40.34.40.40
            <br />
            Email: vn@nahonavocat.fr
          </p>
          <h3>Site host</h3>
          <p>
            IONOS SARL, 7 place de la Gare BP 70109 57201 Sarreguemines Cedex
            (RCS Sarreguemines 431 303 775)
          </p>
          <h3>Content</h3>
          <p>
            Mr. Victor Nahon has an exclusive right to use this site as well as
            the property of the texts, distinctive signs and photographs
            integrated therein. Except with express permission, any partial or
            complete reproduction of these elements is unlawful and may result
            in legal proceedings.
          </p>
          <h3>Protection of personal data</h3>
          <p>
            In accordance with articles 13 and 14 of the General Data Protection
            Regulation (2016/679) and article 32 of the Information and
            Liberties Law of January 6, 1978, the publisher of this site informs
            you that:
          </p>
          <ul>
            <li>
              This site, which is intended to be a simple presentation site, is
              not intended to collect any personal data from its users.
            </li>
            <li>
              As for the personal data that could be communicated to Mr. Victor
              Nahon as part of a lawyer-client relationship, they are protected
              in a secure computer system owned by Mr. Victor Nahon.
            </li>
            <li>
              The communicated personal data is kept for a period of five years
              plus the current year from the last legal or judicial act carried
              out by Mr. Victor Nahon.
            </li>
            <li>
              Any interested party has the right to access, limit, rectify or
              delete their personal data, by sending an email to the following
              address: vn@nahonavocat.fr. He also has the option of filing a
              complaint with the CNIL if he considers that his data has not been
              protected.
            </li>
          </ul>
          <h3>Mediation</h3>
          <p>
            In accordance with the provisions of article L. 612-1 of the French
            Consumer Code, any consumer has the right to use a consumer mediator
            free of charge for the amicable resolution of the dispute that
            opposes him to a professional. Customers meeting the conditions to
            benefit from this mediation may use the consumer mediator of the
            legal profession:
            <a href='https://mediateur-consommation-avocat.fr'>
              https://mediateur-consommation-avocat.fr
            </a>
            .
          </p>
          <h3>Credits</h3>
          <p>
            The image appearing on the homepage is a digital creation by the
            artist Godwits{' '}
            <a href='https://opensea.io/fr/Godwits'>
              https://opensea.io/fr/Godwits
            </a>
            .
          </p>
        </>
      ),
    },
  },

  footer: {
    legal: {
      fr: 'mentions légales',
      en: 'legal notice',
    },
  },
  contact: {
    name: {
      label: {
        fr: 'nom',
        en: 'name',
      },
      placeholder: {
        fr: 'Entrez votre nom',
        en: 'Enter your name',
      },
    },
    email: {
      label: {
        fr: 'email',
        en: 'email',
      },
      placeholder: {
        fr: 'Entrez votre adresse email',
        en: 'Enter your email address',
      },
    },
    message: {
      label: {
        fr: 'message',
        en: 'message',
      },
      placeholder: {
        fr: 'Entrez votre message',
        en: 'Enter your message',
      },
    },
    button: {
      fr: 'Envoyer',
      en: 'Send',
    },
    success: {
      fr: 'Votre message a bien été envoyé. Nous reviendrons vers vous dans les plus brefs délais.',
      en: 'Your message has been successfully sent. We will get back to you as soon as possible.',
    },
    error: {
      fr: "L'envoi de votre message a échoué. Merci de bien vouloir réessayer",
      en: 'The sending of your message has failed. Please try again.',
    },
  },
  cookies: {
    text: {
      fr: "En poursuivant votre navigation sur ce site, vous acceptez l'utilisation de cookies à des fins statistiques.",
      en: 'By continuing your navigation on this site, you accept the use of cookies for statistical purposes.',
    },
    button: {
      fr: 'Je comprends',
      en: 'I understand',
    },
  },
}

export default text
